import Project from "../models/project";

const projects = [
  new Project({
    title: "Cubana Foods",
    image: "/images/cubana-foods-mockup@2x.jpg",
    tags: ["React", "Next", "AWS Lambda"],
    links: {
      demo: "https://cubana-foods.netlify.app",
      github: null,
    },
    theme: "#D3AD6E",
  }),
  new Project({
    title: "Routipro",
    image: "/images/routipro-mockup@2x.jpg",
    tags: ["React", "Sass"],
    links: {
      demo: "https://routipro.samakintunde.com",
      github: "https://github.com/samakintunde37/routipro",
    },
  }),
  new Project({
    title: "Cryptoprice",
    image: "/images/cryptoprice-mockup@2x.jpg",
    tags: ["React", "Typescript"],
    links: {
      demo: "https://samakintunde37.github.io/cryptoprice-react-ts/",
      github: "https://github.com/samakintunde37/cryptoprice-react-ts/",
    },
    theme: "#000000",
  }),
];

export default projects;
