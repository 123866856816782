import React from "react";
import Layout from "../components/common/Layout/layout";
import SEO from "../components/common/Seo/seo.js";
import Box from "../components/common/Box";
import Hero from "../components/home/Hero";
import ProjectsSection from "../components/home/ProjectsSection";
import projects from "../data/projects";
import ProjectsSectionContainer from "../components/home/ProjectsSectionContainer";
import Title from "../components/common/Title";

const IndexPage = () => {
  return (
    <Layout>
      <SEO title="Home" />
      <Box as="main" id="main">
        <Hero />
        <ProjectsSectionContainer>
          <ProjectsSection projects={projects} />
        </ProjectsSectionContainer>
        <Box textAlign="center" paddingTop="xxxl" paddingBottom="xxxl">
          <Title level={3}>
            <span role="img" title="cool emoji" aria-label="cool emoji">
              😎
            </span>
          </Title>
        </Box>
      </Box>
    </Layout>
  );
};

export default IndexPage;
