class Project {
  /**
   * Model Constructor
   * @param {{title: string, image: string, tags: string[], links: {demo: string, github: string}}} data
   */
  constructor(data) {
    this.title = data.title;
    this.image = data.image;
    this.tags = data.tags;
    this.links = data.links;
    this.theme = data.theme;
  }
}

export default Project;
