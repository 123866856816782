import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import styled from "styled-components";
import {
  motion,
  useViewportScroll,
  useTransform,
  useMotionValue,
} from "framer-motion";
import PropTypes from "prop-types";
import ProjectCard from "../ProjectCard";
import BREAKPOINT from "../../../theme/breakpoint";
import { mediaQuery } from "../../../theme/utils";
import { debounce } from "../../../utils/debounce";

const StyledProjectsSection = styled(motion.section)`
  ${mediaQuery.desktop`
    width: 100%;
  `}

  & > * {
    & + * {
      margin-top: ${props => props.theme.spacing.xxl};
    }

    ${mediaQuery.desktop`
      position: sticky;
      top: 0;
      margin-top: 0;
    `}
  }
`;

const ProjectsSection = props => {
  // STATE
  const [isMobile, setIsMobile] = useState(false);
  const [offsetTop, setOffsetTop] = useState(0);
  const [height, setHeight] = useState(0);

  // REFS
  const ref = useRef(null);

  // FRAMER MOTION VALUES
  const { scrollY } = useViewportScroll();
  const y = [
    useTransform(scrollY, [0, height / 3], [0.75, 1]),
    useTransform(scrollY, [offsetTop, offsetTop + height / 3], [0.75, 1]),
    useTransform(
      scrollY,
      [2 * offsetTop, offsetTop + (height * 2) / 3],
      [0.75, 1]
    ),
  ];
  const opacity = [
    useTransform(scrollY, [offsetTop, offsetTop + height / 3], [1, 0]),
    useTransform(
      scrollY,
      [offsetTop + height / 3, offsetTop + (2 * height) / 3],
      [1, 0]
    ),
    useTransform(
      scrollY,
      [offsetTop + (2 * height) / 3, offsetTop + (3 * height) / 3],
      [1, 0]
    ),
  ];

  const yAlt = useMotionValue(1);
  const opacityAlt = useMotionValue(1);

  const updateResizeState = () => {
    if (window.innerWidth < BREAKPOINT.desktop) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
      setOffsetTop(ref.current.offsetTop);
      setHeight(ref.current.scrollHeight);
    }
  };

  const debouncedUpdateResizeState = debounce(updateResizeState, 250);

  // LAYOUT EFFECT
  useLayoutEffect(() => {
    if (!ref.current) return null;
    setOffsetTop(ref.current.offsetTop);
    setHeight(ref.current.scrollHeight);
    debouncedUpdateResizeState();
  }, [ref]);

  // EFFECTS
  useEffect(() => {
    updateResizeState();
    window.addEventListener("resize", debouncedUpdateResizeState);

    return () => {
      window.removeEventListener("resize", debouncedUpdateResizeState);
    };
  }, []);

  // JSX
  return (
    <StyledProjectsSection ref={ref}>
      {props.projects.map((project, index) => (
        <motion.div
          key={index}
          style={{
            scale: !isMobile ? y[index] : yAlt,
            opacity: !isMobile ? opacity[index] : opacityAlt,
          }}
        >
          <ProjectCard project={project} />
        </motion.div>
      ))}
    </StyledProjectsSection>
  );
};

ProjectsSection.propTypes = {
  projects: PropTypes.array.isRequired,
};

export default ProjectsSection;
