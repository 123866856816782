import styled from "styled-components";
import { mediaQuery } from "../../../theme/utils";
import GridContainer from "../../common/Grid/grid-container";

const ProjectsSectionContainer = styled(GridContainer)`
  ${mediaQuery.desktop`
max-width: 100%;
padding:0;
margin:0;
width: 100%;
`}
`;

export default ProjectsSectionContainer;
