import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Text from "../../common/Text";
import HyperAnchor from "../../common/HyperAnchor";
import { remCalc, mediaQuery } from "../../../theme/utils";
import Flex from "../../common/Flex";
import Stack from "../../common/Stack";
import Box from "../../common/Box";
import ResponsiveTitle from "../../common/ResponsiveTitle";

const StyledProjectCard = styled(Flex)`
  flex-direction: column;
  background-color: ${props => props.theme.colors.white};
  overflow: hidden;
  max-height: max-content;
  border: ${props => `${remCalc(1)} solid ${props.theme.colors.gray300}`};

  ${mediaQuery.desktop`
    height: 100vh;
    flex-direction: row;
    width: 100%;
    `}

  & > div:nth-child(1) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    order: 1;
    height: 100%;
    color: ${props => props.theme.colors.gray900};
    width: 100%;

    ${mediaQuery.desktop`
      border-right: ${props =>
        `${remCalc(1)} solid ${props.theme.colors.gray300}`};
      order: 0;
      width: 50%;
    `}

    .links {
      border-top: ${props =>
        `${remCalc(1)} solid ${props.theme.colors.gray200}`};
      padding: ${props => props.theme.spacing.lg};

      ${mediaQuery.desktop`
        padding: ${remCalc(48)};
      `}

      & > * {
        display: flex;
      }
    }

    .content {
      padding: ${remCalc(24)};
      height: 100%;

      ${mediaQuery.desktop`
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: ${remCalc(48)};
      `}

      & > *:not(:first-child) {
        letter-spacing: ${remCalc(1.25)};
      }
    }
  }

  & > div:nth-child(2) {
    width: 100%;
    height: 100%;
    max-height: ${remCalc(400)};
    overflow: hidden;

    /* flex: 1 1 50%; */
    ${mediaQuery.desktop`
      max-height: unset;
      order: 1;
      width: 50%;
    `}

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      object-position: center;
      transition: 0.3s ease-in-out;
    }
  }
`;

const ProjectCard = props => {
  const { project } = props;

  return (
    <StyledProjectCard justify="space-between">
      <div className="left">
        <Stack className="content" gap="lg">
          <ResponsiveTitle
            color={project.theme || "brand500"}
            max={74}
            min={28}
          >
            {project.title}
          </ResponsiveTitle>
          <Box>
            {project.tags.map(tag => (
              <Text color="gray500" key={tag}>
                {tag.toUpperCase()}
              </Text>
            ))}
          </Box>
        </Stack>
        <Box className="links" marginTop="md" background="gray100">
          <Stack direction="horizontal" gap="lg">
            {project.links.demo && (
              <div>
                <HyperAnchor href={project.links.demo}>Demo</HyperAnchor>
              </div>
            )}
            {project.links.github && (
              <div>
                <HyperAnchor href={project.links.github}>Github</HyperAnchor>
              </div>
            )}
          </Stack>
        </Box>
      </div>

      <div className="image-wrapper right">
        <img src={project.image} alt="Project" />
      </div>
    </StyledProjectCard>
  );
};

ProjectCard.defaultProps = {
  project: {
    title: "Routipro",
    tags: ["web", "react", "Node JS"],
    links: {
      demo: "https://routipro.samakintunde.com",
      github: "https://github.com/samakintunde37",
    },
    theme: "brand500",
  },
};

ProjectCard.propTypes = {
  project: PropTypes.shape({
    title: PropTypes.string,
    image: PropTypes.string,
    tags: PropTypes.arrayOf(PropTypes.string),
    links: PropTypes.shape({
      demo: PropTypes.string,
      github: PropTypes.string,
    }),
    theme: PropTypes.string,
  }),
};

export default ProjectCard;
